import React from 'react'
import loadingGif from'../img/loading-gear.gif'
export default function Loading() {
    return (
        <div className='loading'>
           <h4>К сожалению в данный момент страница находятся в разработке, однако мы будем более чем счастливы ответить на любые ваши вопросы по телефону: +7-904-01430-03
Или электронной почте:E-mail: Partner@brain-house.pro</h4>
<img src={loadingGif} ald='building pictur'/>
<img src={loadingGif} ald='building pictur'/>
<img src={loadingGif} ald='building pictur'/>
        </div>
    )
}
