import React, { Component } from 'react'
import logo from '../img/logo1.png';
import {FaAlignRight} from 'react-icons/fa';
import{Link} from 'react-router-dom'
export default class Navbar extends Component {

    state={
        isOpen: false
    }
    handleToggle=()=>{
        this.setState({isOpen: !this.state.isOpen});
    }
    render() {
        return (
            <nav className="navbar">
                <div className="nav-center">
        <div className="nav-header">
            <Link to="/">
                <img src ={logo} alt="brein-hous logo"/>
            </Link>
            <button type="button" className="nav-btn">
            <FaAlignRight className="nav-icon" onClick={this.handleToggle}/>
            </button>
        </div>
        <ul className={this.state.isOpen?"nav-links show-nav":"nav-links"}>
            <li> <Link to="/">O HAC</Link></li>
            <li> <Link to="/solutions">НАШИ РЕШЕНИЯ</Link></li>
        </ul>
                </div>
            </nav>
        )
    }
}
